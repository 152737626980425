<template>
  <v-card class="d-flex justify-center">
    <v-row no-gutters>
      <v-col md="6" offset-md="3">
        <div class="d-flex justify-center info-weather pa-2 pt-8">
          {{ $t('weather.info') }}
        </div>
        <div class="pa-2 d-flex justify-center">
          <v-img
            src="img/no-weather.webp"
            class="mx-auto"
            max-width="100"
            max-height="100"
          ></v-img>
        </div>
        <div class="d-flex justify-center title-weather">
          {{ $t('weather.without_weather') }}
        </div>
        <div class="d-flex justify-center subtitle-weather">
          {{ $t('weather.wait_to_load') }}
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'WithoutWeather',
}
</script>

<style lang="scss" scoped>
.info-weather {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  color: #aab2a9;
}
.title-weather {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.subtitle-weather {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  text-align: center;
  color: #5b6459;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
