<template>
  <v-card>
    <v-card-text>
      <v-row no-gutters cols="12" md="6">
        <v-col cols="7" sm="7" md="7" xs="7">
          <div class="ml-2">
            <div class="title-communication">
              {{ $t('communication') }}
            </div>
          </div>
        </v-col>
      </v-row>
      <v-carousel
        cycle
        height="100%"
        hide-delimiter-background
        show-arrows-on-hover
        interval="4000"
        style="padding-top: 10px"
      >
        <div v-if="countryBr">
          <v-carousel-item
            v-for="(slidePt, i) in slidesPt"
            :key="i"
            :src="slidePt.src"
            style="cursor: pointer"
            @click="redirectTo"
          >
          </v-carousel-item>
        </div>
        <div v-else>
          <v-carousel-item
            v-for="(slideEs, i) in slidesEs"
            :key="i"
            :src="slideEs.src"
          >
          </v-carousel-item>
        </div>
      </v-carousel>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'

export default {
  data() {
    return {
      slidesPt: [
        {
          src: 'img/rural-credit.png',
        },
      ],
      slidesEs: [],
    }
  },

  computed: {
    ...mapGetters('farms', ['currentFarm']),

    countryBr() {
      return this.currentFarm?.location?.country?.acronym === 'BR'
        ? true
        : false
    },
  },

  methods: {
    redirectTo() {
      logEvent(events.panels.clickedBannerCreditares)
      window.open('https://venha.creditares.com.br/digifarmz', '_blank')
    },
  },
}
</script>

<style scoped>
/deep/ .v-carousel__controls__item {
  background-color: #aab2a9 !important;
  width: 7px !important;
  height: 7px !important;
  margin: 0 5px !important;
}

/deep/ .v-item--active {
  color: #39af49 !important;
}

/deep/ .v-icon {
  font-size: 11px !important;
}
.title-communication {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #aab2a9;
}
</style>
