<template>
  <v-container class="without-item-card">
    <div class="df-flex-l df-flex-col justify-center align-center card-content">
      <div v-if="shownIcon" class="content__icon">
        <font-awesome-icon color="#5B6459" :icon="shownIcon" />
      </div>
      <div class="df-flex-sm df-flex-col card__heading">
        <h1 class="heading__title">{{ $t(title) }}</h1>
        <p class="heading__text">{{ $t(text) }}</p>
      </div>
      <df-button
        v-if="showOpenFieldsButton"
        data-id="field-button"
        @click="openFieldsModal"
      >
        {{ $t('WithoutItemCard.field.create_field') }}
      </df-button>
      <df-button v-if="showExecuteSowingButton" @click="openExecuteSowingModal">
        {{ $t('WithoutItemCard.sowing.execute_sowing') }}
      </df-button>
    </div>
    <!-- CM: Vai entrar no projeto de semeaduras -->
    <!-- <execute-season-sowing-modal
      v-if="isExecuteSowingModalOpened"
      @close="closeExecuteSowingModal"
    /> -->
    <modal-talhoes
      v-if="isFieldsModalOpened"
      :farm="currentFarm"
      @close-modal="closeFieldsModal"
    />
  </v-container>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
// import ExecuteSeasonSowingModal from '@/modules/planning/components/modal/ExecuteSeasonSowingModal.vue'
import ModalTalhoes from '@/pages/fazendas/ModalTalhoes.vue'
import { logEvent, events } from '@/services/analytics'
import { mapGetters } from 'vuex'

export default {
  name: 'WithoutItemCard',

  components: {
    DfButton,
    // ExecuteSeasonSowingModal,
    ModalTalhoes,
  },

  props: {
    icon: {
      default: '',
      type: String,
    },
    name: {
      default: 'field',
      type: String,
      validator: (value) =>
        ['field', 'filter', 'sowing', 'execute-sowing'].includes(value),
    },
    text: {
      type: String,
      default: 'Home.without_field.text',
    },
    title: {
      type: String,
      default: 'Home.without_field.title',
    },
  },

  data() {
    return {
      isExecuteSowingModalOpened: false,
      isFieldsModalOpened: false,
    }
  },

  computed: {
    ...mapGetters('farms', ['currentFarm']),
    showExecuteSowingButton() {
      return this.name === 'execute-sowing'
    },
    shownIcon() {
      if (this.icon) return this.icon
      const iconsObj = {
        field: 'vector-square',
        sowing: 'seedling',
        'execute-sowing': 'seedling',
        filter: 'search',
      }
      return iconsObj[this.name]
    },
    showOpenFieldsButton() {
      return this.name === 'field'
    },
  },

  methods: {
    // CM: Vai entrar no projeto de semeaduras
    // closeExecuteSowingModal() {
    //   this.isExecuteSowingModalOpened = false
    // },
    closeFieldsModal() {
      this.isFieldsModalOpened = false
    },
    openExecuteSowingModal() {
      logEvent(events.seasonSowingModule.SeasonSowing.add_sowing_in_season)
      this.isExecuteSowingModalOpened = true
    },
    openFieldsModal() {
      logEvent(events.homeModule.clickedButtonCreateField)
      this.isFieldsModalOpened = true
    },
  },
}
</script>

<style lang="scss" scoped>
.without-item-card {
  border: 1px solid #e6e9e6;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 24px 16px !important;

  .card-content {
    max-width: 488px;
    margin: 0px auto;

    .content__icon {
      border-radius: 999999px;
      background-color: #f8f9f8;
      height: 52px;
      width: 52px;
      position: relative;

      & svg {
        position: absolute;
        top: 18px;
        left: 18px;
      }
    }
    .card__heading {
      .heading__title {
        color: #1a2b46;
        text-align: center;
        font-family: 'Rubik';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;

        &:first-letter {
          text-transform: capitalize;
        }
      }
      .heading__text {
        color: #5b6459;
        text-align: center;
        font-family: 'Rubik';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        &:first-letter {
          text-transform: capitalize;
        }
      }
    }
  }
}
::v-deep .v-btn__content p:first-letter {
  text-transform: capitalize;
}
</style>
