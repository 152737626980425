<template>
  <v-tooltip top>
    <template #activator="{ on, attrs }">
      <font-awesome-icon
        class="icon-action"
        :icon="icon"
        v-bind="attrs"
        v-on="on"
      />
      <span class="pl-2 info-action" v-bind="attrs" v-on="on">{{ info }}</span>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'IconWithInfo',

  props: {
    info: {
      type: String,
      default: '',
    },

    icon: {
      type: String,
      default: '',
    },

    tooltip: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
.icon-action {
  color: #aab2a9;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.info-action {
  color: #5b6459;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
</style>
