import { currentLanguage, formatDecimal, LANG } from '@/language'
import { unitMeasure, unitConversions, unit } from '@/language/unitMeasure'

export const convertToTargetUnit = (
  value,
  type,
  convertDecimalSeparator = true
) => {
  /* 
        If the converted number will be used in math operations or comparisons, the atribute convertDecimalSeparator MUST BE set to FALSE.
        Otherwise, the decimal separator will be set accordingly to the language, which could result in NAN
    */
  const _value = Number(value)
  const convertedValue =
    standardUnit(type) === targetUnit(type)
      ? _value
      : _value * unitConversions[standardUnit(type)][targetUnit(type)]

  if (type === 'temperature' && targetUnit(type) === unit.fahrenheit) {
    return Math.round(convertedValue + 32) /* Tf = (Tc * 9/5) + 32 */
  } else if (convertDecimalSeparator) {
    return formatDecimal(convertedValue, 2)
      .replace(/,00$/, '')
      .replace(/\.00$/, '')
  } else {
    return Number(convertedValue.toFixed(2))
  }
}

export const convertToStandardUnit = (value, type) => {
  const _value = Number(value)
  return standardUnit(type) === targetUnit(type)
    ? Number(_value.toFixed(6))
    : Number(
        (
          _value / unitConversions[standardUnit(type)][targetUnit(type)]
        ).toFixed(6)
      )
}

function targetUnit(type) {
  return unitMeasure[type][currentLanguage()]
}

function standardUnit(type) {
  return unitMeasure[type][LANG.PT]
}
