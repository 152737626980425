<script>
import { mapGetters } from 'vuex'
import PermissionFailedMessage from '@/components/Permission/PermissionFailedMessage'

export default {
  components: {
    PermissionFailedMessage,
  },

  computed: {
    ...mapGetters('permissions', ['hasPermission', 'permissions']),
  },
}
</script>
