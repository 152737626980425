<template>
  <d-number-field
    class="d-converter-field"
    :label="label"
    v-model="rawValue"
    :errors="errors"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :custom-class="customClass"
    :background-color="backgroundColor"
    :dense="dense"
    :outlined="outlined"
    :color="color"
    @blur="blur"
  >
    <template #append>
      <slot name="append"></slot>
    </template>
  </d-number-field>
</template>

<script>
import { convertToTargetUnit, convertToStandardUnit } from './converter'
import DNumberField from '@/components/Form/DNumberField.vue'
export default {
  components: {
    DNumberField,
  },
  data() {
    return {
      rawValue: null,
    }
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    unitMeasure: {
      type: String,
      required: true,
    },

    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: null,
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue == '' && this.rawValue == '') {
          return
        }
        const formatted = convertToTargetUnit(newValue, this.unitMeasure, false)
        if (formatted !== this.rawValue) {
          this.rawValue = formatted
        }
      },
      immediate: true,
    },
    rawValue: {
      handler(newRawValue, oldRawValue) {
        const unformattedValue = convertToStandardUnit(
          newRawValue,
          this.unitMeasure
        )
        const oldUnformattedValue = convertToStandardUnit(
          oldRawValue,
          this.unitMeasure
        )

        if (unformattedValue !== oldUnformattedValue) {
          this.$emit('input', unformattedValue)
          this.$emit('change', unformattedValue)
        }
      },
    },
  },
  methods: {
    blur() {
      this.$emit('blur')
    },
  },
}
</script>
