<template>
  <v-card>
    <v-card-text>
      <v-row no-gutters cols="12" md="6">
        <v-col cols="7" sm="7" md="7" xs="7">
          <div class="ml-2">
            <div class="date-quote">
              <h4 class="quotation-date">
                <b>{{ quotation.date.formatDate() }}</b>
              </h4>
            </div>
            <span class="currency-usd mr-1">US$</span>
            <span class="usd-value">{{ formatPrice(quotation.close)[0] }}</span>
            <span class="usd-value-cents"
              >,{{ formatPrice(quotation.close)[1] }}&nbsp;&nbsp;</span
            >
            <span>
              <v-icon
                v-if="quotation.percentage_variation >= 0"
                x-small
                color="green"
                >mdi-arrow-up</v-icon
              >
              <v-icon v-else x-small color="red">mdi-arrow-down</v-icon>
              <span class="percentage-variation"
                >&nbsp;{{ porcentageVariation }}%</span
              >
            </span>
            <div class="info-ordinance">
              {{ $t('closure') }} (US$ / {{ quotation.contract_type }})
            </div>
          </div>
        </v-col>
        <v-col cols="5" sm="5" md="5" xs="5" v-if="showDollarquotation">
          <div>
            <div class="dol-quotation">{{ this.$t('dollar_rate') }}</div>
            <div class="line-brl-quotation">
              <span class="currency-brl">{{ $t('moeda') }}</span>
              <span class="convert-brl-quotation"
                >&nbsp;&nbsp;{{
                  formatPriceCurrentDollarValue(dollarQuotation['usd_brl'])
                }}</span
              >
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row align-center col="12" md="6">
        <v-col cols="12" sm="12" md="12" lg="7">
          <v-select
            v-model="quotation"
            :items="quotationsSelect"
            item-value="id"
            item-text="name"
            outlined
            return-object
            hide-details
            :label="$t('commodity')"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import api from '@/services/api'
import { mapGetters } from 'vuex'
import { LANG } from '@/language'

export default {
  data() {
    return {
      quotations: [],
      quotation: {
        id: null,
        name: null,
        percentage_variation: 0,
        contract_type: null,
        close: 0,
        date: new Date().toLocaleDateString(),
      },
      dollarQuotation: 0,
    }
  },

  watch: {
    quotations(val, old) {
      if (val !== old) {
        this.quotation = val[0]
      }
    },

    currentLanguage() {
      this.$root.$i18n.locale = localStorage.getItem('language')
    },
  },

  async created() {
    await this.setQuotations()
    await this.setDollarQuotation()
  },

  computed: {
    ...mapGetters('user', ['currentLanguage']),
    quotationsSelect() {
      return [...this.quotations].sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    },

    porcentageVariation() {
      return this.quotation.percentage_variation
        ? this.quotation.percentage_variation.toFixed(1).replace('-', '')
        : 0
    },

    showDollarquotation() {
      return this.currentLanguage !== LANG.EN
    },
  },

  methods: {
    async setQuotations() {
      const response = await api.quotations.getAllOrdinaces()
      this.quotations = response.data
    },

    async setDollarQuotation() {
      const response = await api.quotations.getDollarQuotation()
      this.dollarQuotation = response.data
    },

    formatPrice(price) {
      if (this.currentLanguage === LANG.PT) {
        return this.$currency(price).replace('R$', '').split(',')
      } else {
        return this.$currency(price).replace('$', ' ').split('.')
      }
    },

    formatPriceCurrentDollarValue(price) {
      if (this.currentLanguage === LANG.PT) {
        return this.$currency(price).replace('R$', '')
      } else {
        return this.$currency(price).replace('$', ' ')
      }
    },
  },
}
</script>

<style scoped>
.dol-quotation,
.convert-brl-quotation,
.currency-brl,
.usd-value,
.info-ordinance,
.currency-usd,
.usd-value-cents {
  font-family: Rubik, sans-serif;
  font-style: normal;
}

.date-quote,
.percentage-variation {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
}

.date-quote,
.usd-value-cents {
  text-transform: uppercase;
}

.convert-brl-quotation,
.usd-value,
.usd-value-cents {
  color: #1a1a1a;
}

.date-quote,
.usd-value,
.usd-value-cents,
.percentage-variation {
  font-weight: 700;
}

.convert-brl-quotation,
.currency-brl {
  font-weight: 600;
}

.dol-quotation,
.info-ordinance {
  font-weight: 500;
}

.currency-brl,
.currency-usd {
  color: #aab2a9;
}
.quotation-date {
  padding: 6px 0px;
}
.currency-usd {
  font-weight: 400;
  font-size: 19px;
  line-height: 20px;
}
.date-quote {
  font-size: 10px;
  line-height: 15px;
  color: #5b6459;
}
.dol-quotation {
  font-size: 10px;
  line-height: 20px;
  color: #797979;
  padding-top: 15px;
}
.convert-brl-quotation {
  font-size: 14px;
  line-height: 18px;
  margin-left: -5px;
}
.currency-brl {
  font-size: 12px;
  line-height: 10px;
}
.usd-value {
  font-size: 28px;
  line-height: 18px;
  margin-left: -8px;
}
.info-ordinance {
  font-size: 10px;
  line-height: 20px;
}
.usd-value-cents {
  font-size: 14px;
  line-height: 18px;
}
.percentage-variation {
  font-size: 12px;
  line-height: 18px;
}
.line-brl-quotation {
  margin-top: -4px;
}
::v-deep .v-sheet.v-card {
  box-shadow: none !important;
}
</style>
