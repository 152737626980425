<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import 'vuetify/dist/vuetify.min.css'

export default {
  name: 'MainApp',
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Rubik:wght@300;400;500;600;700&family=Source+Sans+Pro:wght@300;400;500;600;700&display=swap');
</style>
