<template>
  <v-card>
    <v-tabs v-model="tab" flat background-color="transparent" grow>
      <v-tab v-for="item in items" :key="item.label">
        {{ item.label }}
      </v-tab>
    </v-tabs>
    <quotation-disable-card v-if="!enabledFarm" />
    <v-tabs-items v-model="tab" v-else>
      <v-tab-item v-for="item in items" :key="item.label">
        <component :is="item.component" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import PhysicalMarketCard from '@/components/MarketQuotations/PhysicalMarketCard.vue'
import ChicagoCard from '@/components/MarketQuotations/ChicagoCard.vue'
import BrazilChicagoCard from '@/components/MarketQuotations/BrazilChicagoCard.vue'
import QuotationDisableCard from '@/components/MarketQuotations/QuotationDisableCard.vue'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      tab: null,
      items: [],
    }
  },

  components: {
    PhysicalMarketCard,
    ChicagoCard,
    BrazilChicagoCard,
    QuotationDisableCard,
  },

  computed: {
    ...mapGetters('farms', ['enabledFarm']),
    ...mapGetters('user', ['isBrazil']),
  },

  mounted() {
    if (this.isBrazil) {
      this.items = [
        {
          label: this.$t('physical_market'),
          component: PhysicalMarketCard,
        },
        {
          label: this.$t('stock_chicago'),
          component: BrazilChicagoCard,
        },
      ]
    } else {
      this.items = [
        {
          label: this.$t('stock_chicago'),
          component: ChicagoCard,
        },
      ]
    }
  },
}
</script>

<style scoped>
::v-deep .v-tab {
  background-color: #f8f9f8;
}

::v-deep .v-tabs-slider {
  height: 0px !important;
}

::v-deep .v-tabs--grow > .v-tabs-bar .v-tab {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  text-transform: uppercase !important;
  color: #797979;
}

.v-tabs {
  margin-bottom: 0px !important;
}

::v-deep .v-tab--active {
  background-color: #fff !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

::v-deep .v-tabs-bar {
  z-index: 1;
}
</style>
