export default {
  WithoutItemCard: {
    field: {
      title: 'Você ainda tem nenhuma semeadura ativa',
      text: 'Todas as suas semeaduras aparecerão aqui.',
      create_field: 'Criar talhão',
    },
    sowing: {
      title: 'Você ainda tem nenhuma semeadura ativa',
      text: 'Todas as suas semeaduras aparecerão aqui.',
      execute_sowing: 'Executar semeadura',
    },
    filter: {
      not_found_title: 'Não encontramos resultados',
      not_found_text: 'Tente mudar os filtros',
    },
  },
}
