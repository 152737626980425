<template>
  <v-text-field
    ref="DnumberField"
    class="d-number-field"
    data-cy="qa-number-field"
    :label="formattedLabel"
    :error-messages="errors"
    v-model="inputValue"
    :disabled="disabled"
    :clearable="clearable"
    v-mask="numberMask(precision)"
    @click:clear="clearValue"
    :class="customClass"
    :background-color="backgroundColor"
    :dense="dense"
    :outlined="outlined"
    :color="color"
    @blur="blur"
    ><template #append>
      <slot name="append"></slot>
    </template>
  </v-text-field>
</template>

<script>
import { formatDecimal } from '@/language'

export default {
  name: 'DNumberField',
  props: {
    label: {
      type: String,
      required: true,
    },
    precision: {
      type: Number,
      default: 2,
    },
    required: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: null,
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      inputValue: null,
    }
  },
  computed: {
    formattedLabel() {
      const translatedLabel = this.$t(this.label)
      if (!this.required) {
        return translatedLabel
      }
      return `${translatedLabel} ${this.$t('required')}`
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue == '' && this.inputValue == '') {
          // fix infinite loop
          return
        }

        const formatted = formatDecimal(newValue, this.precision)
        if (formatted !== this.inputValue) {
          this.inputValue = formatted
        }
      },
    },
    inputValue: {
      handler(newInputValue, oldInputValue) {
        const unformattedValue = this.parseToFloat(newInputValue)
        const oldUnformattedValue = this.parseToFloat(oldInputValue)

        if (unformattedValue !== oldUnformattedValue) {
          this.$emit('input', unformattedValue)
          this.$emit('change', unformattedValue)
          this.$emit('formattedValue', newInputValue)
        }
      },
    },
  },
  methods: {
    clearValue() {
      this.$refs.DnumberField.$el.getElementsByTagName('Input')[0].value = '0'
    },
    parseToFloat(value) {
      if (value === null) {
        return '0'
      }
      // https://stackoverflow.com/questions/29340525/add-decimal-to-last-two-numbers-in-numeric-string
      const onlyDigits = value.replace(/\D/g, '')

      if (onlyDigits.length == 1) {
        return onlyDigits.replace(/\b(\d)\b/, '0.0$1')
      }
      if (onlyDigits.length == 2) {
        return onlyDigits.replace(/\b(\d{2})\b/, '0.$1')
      }
      if (onlyDigits.length > 2) {
        return onlyDigits.replace(/\b(\d+)(\d{2})\b/, '$1.$2')
      }

      return ''
    },
    numberMask(precision) {
      return (value) => {
        const formattedWDecimal = this.parseToFloat(value)

        return isNaN(formattedWDecimal)
          ? [value.slice(0, -1)]
          : [formatDecimal(formattedWDecimal, precision)]
      }
    },
    blur() {
      this.$emit('blur')
    },
  },
}
</script>

<style>
.d-number-field.v-text-field .v-input__append-inner {
  margin-top: 11px !important;
}
</style>
