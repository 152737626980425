<template>
  <d-number-field v-model="showValue" :label="label"></d-number-field>
</template>

<script>
import DNumberField from '@/components/Form/DNumberField'

export default {
  components: { DNumberField },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },

    cropId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      showValue: null,
      label: '',
    }
  },

  watch: {
    value: {
      handler(newValue) {
        this.showValue = newValue
      },
      immediate: true,
    },

    showValue: {
      handler(newValue) {
        this.$emit('input', newValue)
      },
    },
  },

  mounted() {
    this.setLabel(this.cropId)
  },

  methods: {
    setLabel(value) {
      const crops = {
        1: 'soybean',
        2: 'wheat',
      }

      this.label =
        this.$t('price') +
        ' ' +
        this.$t('crop.' + crops[value])?.toLowerCase() +
        ' (' +
        this.$t('moeda') +
        '/' +
        this.$t('toolbar.price_per_seed') +
        ') '
    },
  },
}
</script>

<style></style>
