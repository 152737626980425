<template>
  <v-dialog v-model="isOpen" width="636px" persistent>
    <v-card class="modal-card">
      <v-card-title>
        <v-row no-gutters>
          <v-col md="12" sm="12" xs="12" :class="classTitle">
            <df-title>{{ titleModal }}</df-title>
            <div>
              <v-img :src="upsellLogoLastPlan" width="177px" />
            </div>
            <v-btn class="modal__close-btn" icon @click="closeModal">
              <v-icon class="icon-close">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="df-flex-m df-flex-column">
        <df-carousel hide-delimiters height="300px" :images="images" />
        <card-info-plan v-if="isOpen" @close-modal="closeModal" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CardInfoPlan from '@/components/Cards/CardInfoPlan.vue'
import DfCarousel from '@/components/Carousels/DfCarousel.vue'
import DfTitle from '@/components/Texts/DfTitle.vue'
import PlanMixin from '@/components/PlanMixin'

export default {
  name: 'PlanPresentationModal',

  mixins: [PlanMixin],

  components: {
    CardInfoPlan,
    DfTitle,
    DfCarousel,
  },

  data() {
    return {
      isOpen: false,
    }
  },

  props: {
    classTitle: {
      type: String,
      default:
        'd-flex flex-column flex-sm-row justify-space-around align-center',
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: () => [],
    },
    titleModal: {
      type: String,
      default: '',
    },
  },

  watch: {
    dialog(newDialog) {
      this.isOpen = newDialog
    },
    isOpen(newIsOpen) {
      if (!newIsOpen) {
        this.closeModal()
      }
    },
  },

  async created() {
    await this.getLastUpsellPlan()
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-card {
  background-color: #fff;
  border-radius: 8px;

  .modal__close-btn {
    position: absolute;
    top: 18px;
    right: 18px;

    .icon-close {
      color: #aab2a9 !important;
      text-align: center;
      font-family: 'Font Awesome 5 Free';
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: 9.5px;
      letter-spacing: 0.127px;
    }
  }
}
::v-deep .v-image__image.v-image__image--cover {
  background-size: contain !important;
}
::v-deep .v-dialog {
  overflow: hidden;
}
.primary-text {
  color: #1a2b46;
  text-align: center;
  font-family: Rubik;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0;
}
.secondary-text {
  color: #5b6459;
  text-align: center;
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
}
.text-link {
  color: #5b6459;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration-line: underline;
}
.button-view-icon {
  color: #ffffff;
}
.button-view {
  background-color: #39af49 !important;
}
.card-actions-custom {
  background-color: #fff;
  position: sticky;
  padding-bottom: 60px;
}
</style>
