import { helpers } from 'vuelidate/lib/validators'
import { parseFloatNumber } from '@/utils/number'
import { convertToTargetUnit } from '@/components/Form/converter'

export const inRange = ({ min, max, type }) =>
  helpers.withParams(
    {
      messageKey: 'validations.in_range',
      properties: {
        min: type != '' ? convertToTargetUnit(min, type) : min,
        max: type != '' ? convertToTargetUnit(max, type) : max,
      },
    },
    (v) => {
      const value = parseFloatNumber(v)
      // FIXME: we should add decimal cases into field area column in the database
      // the problem could be tested with a field with 37.07 acres
      const fixedValue = value && value > 0 ? Number(value).toFixed(2) : null
      return (
        !helpers.req(fixedValue) || (fixedValue >= min && fixedValue <= max)
      )
    }
  )

export const maxValue = (max) =>
  helpers.withParams(
    { messageKey: 'validations.max_value', properties: { max } },
    (value) => {
      return (
        !helpers.req(value) || parseFloatNumber(value) <= parseFloatNumber(max)
      )
    }
  )

export const notEmpty = () =>
  helpers.withParams(
    { messageKey: 'campo_obrigatorio', properties: {} },
    (value) => {
      return value > 0
    }
  )

export const notNull = () =>
  helpers.withParams(
    { messageKey: 'campo_obrigatorio', properties: {} },
    (value) => {
      return value !== null
    }
  )
