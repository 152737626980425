<template>
  <v-row align-content="center" justify="center" no-gutters class="loader">
    <v-col v-if="showLoadingText" cols="12" class="subtitle-1 text-center">
      {{ $t('loading') }}
    </v-col>
    <v-col cols="12" class="text-center">
      <v-progress-circular indeterminate color="green"></v-progress-circular>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    showLoadingText: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
}
</script>

<style scoped>
.loader {
  height: 100%;
}
</style>
