<template>
  <v-card>
    <v-card-text class="mb-n3">
      <div v-if="title">
        <v-row align="center">
          <v-col cols="12">
            <df-title class="df-title--gray-uppercase pl-5 pt-3">
              {{ title }}
            </df-title>
          </v-col>
        </v-row>
      </div>
      <v-list v-for="(object, index) in objects" :key="index">
        <v-row no-gutters align="center">
          <v-col cols="6" class="pl-5" @click="clickButton(object)">
            <df-title class="df-title--secondary pb-3 pt-3">
              {{ object.name }}
            </df-title>
          </v-col>
          <v-col cols="3" class="text-end" v-if="iconTwo">
            <icon-with-info
              :info="object.attribute_two"
              :tooltip="tooltipTwo"
              :icon="iconTwo"
            ></icon-with-info>
          </v-col>

          <v-col :cols="!iconTwo ? 6 : 3" class="text-end pr-5">
            <icon-with-info
              :info="object.attribute_one"
              :tooltip="tooltipOne"
              :index="index"
              :icon="iconOne"
            ></icon-with-info>
          </v-col>
        </v-row>

        <v-divider
          class="mb-n3 style-divider"
          v-if="limitDivider(index)"
        ></v-divider>
      </v-list>
    </v-card-text>

    <v-card-actions class="fixed-actions pl-5 mb-n4 pb-4" v-if="iconButton">
      <df-button
        class="df-button--primary d-flex justify-start align-center"
        flank="fa-pull-left pr-1 ml-3 fa-1x"
        :icon="iconButton"
        text
        @click="actionButton"
      >
        {{ textButton }}
      </df-button>
    </v-card-actions>
  </v-card>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfTitle from '../Texts/DfTitle.vue'
import IconWithInfo from '../Info/IconWithInfo.vue'
export default {
  name: 'CardDropDown',

  components: {
    DfButton,
    DfTitle,
    IconWithInfo,
  },

  props: {
    objects: {
      type: Array,
      required: true,
    },

    textButton: {
      type: String,
      default: '',
    },

    iconButton: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },

    iconOne: {
      type: String,
      default: '',
    },

    iconTwo: {
      type: String,
      default: '',
    },

    tooltipOne: {
      type: String,
      default: '',
    },

    tooltipTwo: {
      type: String,
      default: '',
    },
  },

  methods: {
    clickButton(object) {
      this.$emit('clickButton', object)
    },

    actionButton() {
      this.$emit('actionButton')
    },

    limitDivider(index) {
      return index < this.objects.length - 1
    },
  },
}
</script>

<style scoped>
.fixed-actions {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 2;
}

.style-divider {
  width: 100%;
  margin-left: 0%;
  background: #f5f5f5;
}
</style>
