<script>
import { mapGetters } from 'vuex'
import LayoutMixin from './LayoutMixin.vue'

export default {
  mixins: [LayoutMixin],
  data() {
    return {
      lastPlan: '',
    }
  },

  computed: {
    ...mapGetters('user', [
      'currentUser',
      'isPlanLevelOne',
      'isPlanLevelThree',
    ]),

    logo() {
      return this.isMobile
        ? this.currentUser?.plan.logo_uri.mobile
        : this.currentUser?.plan.logo_uri.web
    },

    upsellLogoLastPlan() {
      return this.lastPlan?.logo_uri?.web
    },

    upsellNameLastPlan() {
      return this.lastPlan.name
    },
  },

  methods: {
    async getLastUpsellPlan() {
      const data = await this.$api.plans.getAll()
      const plans = data.data

      this.lastPlan = plans.reduce((prev, current) =>
        prev.priority_level > current.priority_level ? prev : current
      )
    },
  },
}
</script>
