<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      DSMobile: 768,
      DSTablet: 1264,
      windowWidth: window.innerWidth,
    }
  },

  created() {
    window.addEventListener('resize', this.updateWindowWidth)
  },

  destroyed() {
    window.removeEventListener('resize', this.updateWindowWidth)
  },

  computed: {
    ...mapGetters('user', ['currentUser']),

    isMobile() {
      const breakpoint = this.$vuetify.breakpoint.name

      return ['sm', 'xs'].includes(breakpoint)
    },
    isDSMobile() {
      return this.windowWidth < this.DSMobile
    },
    isDSTablet() {
      return (
        this.windowWidth >= this.DSMobile && this.windowWidth < this.DSTablet
      )
    },
    logo() {
      return this.currentUser?.plan.logo_uri.web
    },
  },

  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth
    },
  },
}
</script>
