<template>
  <v-tour
    name="tourFieldNotes"
    :steps="steps"
    :options="tourConfig"
    class="class-tour"
    :callbacks="myCallbacks"
  />
</template>

<script>
export default {
  name: 'TourFieldNotes',
  data() {
    return {
      tourConfig: {
        useKeyboardNavigation: true,
        highlight: false,
        labels: {
          buttonSkip: this.$t('tour.skip'),
          buttonPrevious: this.$t('back'),
          buttonNext: this.$t('next'),
          buttonStop: this.$t('tour.end'),
        },
      },
      steps: [
        {
          target: '[data-v-step="notes-navbar"]',
          content: this.$t('tour.notes_navbar'),
          params: {
            placement: 'top',
          },
        },
        {
          target: '[data-v-step="create-note"]',
          content: this.$t('tour.create_note'),
        },
        {
          target: '[data-v-step="filter-notes"]',
          content: this.$t('tour.filter_notes'),
          params: {
            placement: 'top',
          },
        },

        {
          target: '[data-v-step="user-menu"]',
          content: this.$t('tour.user_menu'),
          params: {
            placement: 'bottom',
          },
        },

        {
          target: '[data-v-step="menu-changed"]',
          content: this.$t('tour.menu_changed'),
          params: {
            placement: 'right',
          },
        },
      ],
      myCallbacks: {
        onFinish: this.myCustomFinishStepCallback,
        onSkip: this.myCustomFinishStepCallback,
      },
    }
  },

  methods: {
    //TODO: Discoment this when the tour is ready
    // myCustomFinishStepCallback() {
    //   localStorage.setItem('tour-notes', true)
    // },
  },
}
</script>

<style scoped>
::v-deep .v-step {
  background: white !important;
  color: black !important;
  max-width: 320px !important;
  border-radius: 8px !important;
  font-family: Rubik, sans-serif !important;
  text-align: start !important;
  font-weight: 400;
  line-height: 20px;
  font-size: 16px;
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f !important;
}

::v-deep .v-step__button {
  color: black !important;
  border-radius: 8px !important;
  height: 32px !important;
  top: 3173px !important;
  left: 5262px !important;
  border-radius: 4px !important;
  padding: 4px 12px 4px 12px !important;
  gap: 8px !important;
}

::v-deep .v-step__button-next {
  background: #62cc70 !important;
  color: white !important;
  font-family: Source Sans Pro, sans-serif !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

::v-deep .v-step__button-previous {
  background: #f2f2f2 !important;
  color: #000000 !important;
  font-family: Source Sans Pro, sans-serif !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
}
::v-deep .v-step__button-skip {
  background: #f2f2f2 !important;
  color: black !important;
  font-family: Source Sans Pro, sans-serif !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

::v-deep .v-step__button-stop {
  background: #f2f2f2 !important;
  color: black !important;
  font-family: Source Sans Pro, sans-serif !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
}
::v-deep .v-step__arrow {
  background: white !important;
  box-shadow: 10px 5px 5px black;
}
</style>
