<template>
  <v-carousel :hide-delimiters="hideDelimiters" :height="height">
    <v-carousel-item v-for="(item, i) in images" :key="i" :src="item" />
  </v-carousel>
</template>

<script>
export default {
  name: 'DfCarousel',

  props: {
    height: {
      default: '',
      type: String,
    },
    hideDelimiters: {
      default: false,
      type: Boolean,
    },
    images: {
      default: () => [],
      type: Array,
    },
  },
}
</script>
