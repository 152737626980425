<template>
  <v-card class="bs-none">
    <v-card-text>
      <v-row no-gutters cols="12" md="6">
        <v-col cols="7" sm="7" md="7" xs="7">
          <div class="ml-2">
            <div class="date-quote">
              <h4 class="quotation-date">
                <b>{{ quotation.date.formatDate() }}</b>
              </h4>
            </div>
            <span class="currency-usd mr-1">US$</span>
            <span class="usd-value">{{ brlPrice[0] }}</span
            ><span class="usd-value-cents"
              >,{{ brlPrice[1].toString().substr(0, 2) }}&nbsp;&nbsp;</span
            >
            <span>
              <v-icon
                v-if="quotation.percentage_variation >= 0"
                x-small
                color="green"
                >mdi-arrow-up</v-icon
              >
              <v-icon v-else x-small color="red">mdi-arrow-down</v-icon>
              <span class="percentage-variation"
                >&nbsp;{{ porcentageVariation }}%&nbsp;&nbsp;</span
              >
            </span>
            <div class="info-ordinance">
              {{ this.$t('closure') }} (US$ / {{ quotation.contract_type }})
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row align-center col="12" md="6">
        <v-col cols="12" sm="12" md="12" lg="7">
          <v-select
            v-model="quotation"
            :items="quotationsSelect"
            item-value="id"
            item-text="name"
            outlined
            return-object
            hide-details
            :label="$t('commodity')"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import api from '@/services/api'

export default {
  data() {
    return {
      quotations: [],
      quotation: {
        id: null,
        name: null,
        percentage_variation: 0,
        contract_type: null,
        close: 0,
        date: new Date().toLocaleDateString(),
      },
    }
  },

  watch: {
    quotations(val, old) {
      if (val !== old) {
        this.quotation = val[0]
      }
    },
  },

  async created() {
    await this.setQuotations()
  },

  computed: {
    brlPrice() {
      return this.quotation && this.quotation.close
        ? this.quotation.close.split('.')
        : ['0', '00']
    },

    quotationsSelect() {
      return [...this.quotations].sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    },

    porcentageVariation() {
      return this.quotation.percentage_variation
        ? this.quotation.percentage_variation.toFixed(1).replace('-', '')
        : 0
    },
  },

  methods: {
    async setQuotations() {
      const response = await api.quotations.getAllOrdinaces()
      this.quotations = response.data
    },
  },
}
</script>

<style scoped>
::v-deep .v-card__text {
  margin-top: 0px !important;
}

.bs-none {
  box-shadow: none !important;
}

.usd-value,
.info-ordinance,
.currency-usd,
.usd-value-cents {
  font-family: Rubik, sans-serif;
  font-style: normal;
}

.date-quote,
.title-stock-chicago,
.percentage-variation {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
}

.date-quote,
.usd-value-cents {
  text-transform: uppercase;
}

.usd-value,
.usd-value-cents {
  color: #1a1a1a;
}

.date-quote,
.usd-value,
.usd-value-cents,
.percentage-variation {
  font-weight: 700;
}
.currency-usd {
  font-weight: 400;
  font-size: 19px;
  line-height: 20px;
  color: #aab2a9;
}
.title-stock-chicago {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #aab2a9;
}
.quotation-date {
  padding: 6px 0px;
}
.date-quote {
  font-size: 10px;
  line-height: 15px;
  color: #5b6459;
}
.usd-value {
  font-size: 28px;
  line-height: 18px;
}
.info-ordinance {
  font-size: 10px;
  line-height: 20px;
  font-weight: 500;
}
.usd-value-cents {
  font-size: 14px;
  line-height: 18px;
}
.percentage-variation {
  font-size: 12px;
  line-height: 18px;
}
</style>
