<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="682">
      <template #activator="{ on, attrs }">
        <div>
          <span class="text-caption mr-1"> {{ $t('tutorial') }}</span>
          <v-btn icon v-bind="attrs" v-on="on" x-small>
            <v-icon medium>mdi-youtube-tv</v-icon>
          </v-btn>
        </div>
      </template>
      <v-card align="center">
        <v-col>
          <v-row>
            <iframe
              v-if="dialog"
              width="682"
              height="384"
              :src="urlVideo"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </v-row>
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ModalVideo',

  data() {
    return {
      dialog: false,
    }
  },

  props: {
    urlVideo: { type: String, required: true },
  },
}
</script>
